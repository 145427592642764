import CloseXGnb from '@components/elements/CloseGnb';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import WidgetInfo from '@assets/images/png/widgetInfo.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const WidgetPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <CloseXGnb
        onClick={() => {
          navigate(-1);
        }}
        size="semiLarge"
      />
      <Field>
        <Img src={WidgetInfo} />
      </Field>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 428px;
  padding-top: 50px;
  background-color: ${COLORS.WHITE};
`;

const Field = styled.div`
  width: 100%;
  height: min-content;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
`;

export default WidgetPage;
