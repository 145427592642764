import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ThumbYellowIcon } from '@assets/svgs/_Icons';
import Popup from '@components/elements/Popup/Popup';
import BackArrowBtn from '@components/Molecules/BackArrowBtn';
import Skeleton from '@components/Skeleton';
import useModal from '@hooks/useModal';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import SaveModal from './SaveModal';
import { myRoomInfoState, uniqueCartRoomItemsState } from '@states/myRoomState';
import CartRoomItemBottomSheet from './CartRoomItemBottomSheet';
import useToastModal from '@hooks/useToastModal';
import { useRef } from 'react';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { spinnerState } from '@states/globalAlert';

interface Props {
  isLoading?: boolean;
  onSave?: () => Promise<void>;
}

const RoomEditorTopMenu = ({ isLoading, onSave }: Props) => {
  const { sendAdBrixData } = useAdBrix();
  const navigate = useNavigate();
  const saveRef = useRef(false);
  const { openModal, closeModal } = useModal();
  const { openModal: openToast } = useToastModal();
  const myRoomInfo = useRecoilValue(myRoomInfoState);
  const uniqueCartRoomItems = useRecoilValue(uniqueCartRoomItemsState);
  const setSpinner = useSetRecoilState(spinnerState);

  const handleClickBackButton = () => {
    openModal(Popup, {
      buttonText1: '아니요',
      buttonText2: '네',
      headText: '방 꾸미기를 정말 그만두시겠어요?',
      subText1: '저장하지 않고 그만두면 꾸민 내용이 모두 사라져요',
      onClick: () => {
        navigate(-1);
        closeModal(Popup);
      },
      cancel: () => {
        closeModal(Popup);
      },
    });
  };

  const handleClickSaveButton = () => {
    if (uniqueCartRoomItems.length > 0) {
      openModal(CartRoomItemBottomSheet, {
        onClose: () => {
          closeModal(CartRoomItemBottomSheet);
        },
      });
      return;
    }
    openModal(SaveModal, {
      onClose: () => {
        closeModal(SaveModal);
      },
      onSubmit: async () => {
        if (saveRef.current) return;
        saveRef.current = true;
        setSpinner(false);
        await onSave?.();
        navigate('/myPage/mainRoom');
        openToast({ children: <p>방 저장 완료!</p> });
        closeModal(SaveModal);
        sendAdBrixData('customEvent', {
          eventKey: 'complete_room_edit',
        });
        saveRef.current = false;
        setSpinner(true);
      },
    });
  };

  if (isLoading) {
    return (
      <Wrapper>
        <RoomInfoField>
          <BackArrowBtn />
          <Skeleton width={40} height={40} radius="50%" />
          <InfoBox>
            <Skeleton width={91} height={15} radius={120} margin="0px 0px 2px 0px" />
            <Skeleton width={32} height={10} radius={120} />
          </InfoBox>
        </RoomInfoField>
        <Skeleton width={62} height={36} radius={120} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <RoomInfoField>
        <BackArrowBtn onClickEvent={handleClickBackButton} />
        <Img src={myRoomInfo.thumbnail} />
        <InfoBox>
          <Title>{myRoomInfo.roomName}의 방</Title>
          <LikeBox>
            <ThumbYellowIcon width={14} height={14} />
            <Count>{myRoomInfo.likes}</Count>
          </LikeBox>
        </InfoBox>
      </RoomInfoField>
      <SaveButton onClick={handleClickSaveButton}>저장</SaveButton>
    </Wrapper>
  );
};

export default RoomEditorTopMenu;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 18px 0px 20px;
  width: 100%;
  max-width: 428px;
  height: 56px;
  position: fixed;
  top: 0;
  background-color: ${COLORS.WHITE};
  z-index: ${LEVEL.MODAL_BASIC};
`;

const RoomInfoField = styled.div`
  display: flex;
  column-gap: 10px;
  width: 100%;
  overflow: hidden;
`;

const Img = styled.img`
  margin-left: 2px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${COLORS.PRIMITIVES_GRAY_300};
  border: 0.7px solid ${COLORS.PRIMITIVES_GRAY_300};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow: hidden;
`;

const Title = styled.p`
  margin-top: 2px;
  color: ${COLORS.BLACK};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 30px;
  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const Count = styled.p`
  color: ${COLORS.PRIMITIVES_GRAY_600};
  font-family: Campton;
  font-size: 13px;
  font-weight: 900;
  line-height: 100%;
`;

const LikeBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const SaveButton = styled.button`
  background-color: ${COLORS.BLACK};
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.WHITE};
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.5px;
  height: 36px;
  padding: 0px 18px;
  white-space: nowrap;
`;
