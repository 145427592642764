import ReactGA from 'react-ga4';

type Setting = {
  page: string;
  title: string;
};

type Convert = {
  [key: string]: string;
};

type Handler = {
  [key: string]: () => string;
};

const CONVERT_URL: Convert = {
  '': '/home/record',
  '/': '/home/record',
};

const CONVERT_TITLE: Convert = {
  '': '기록 메인',
  '/': '기록 메인',
  '/home/record': '기록 메인',
  '/login': '로그인 페이지',
  '/loginInquiry': '로그인 문의',
  '/ketoLogin': '아이디 로그인',
  '/KetoRedirect/A': '로그인 리다이렉트',
  '/KetoRedirect/K': '로그인 리다이렉트',
  '/KetoRedirect/N': '로그인 리다이렉트',
  '/KetoRedirect/C': '로그인 리다이렉트',
  '/KetoRedirect/M': '로그인 메인테인',
  '/welcome': '회원 가입 완료',
  '/userProfile/5': '가입 후 프로필 입력 완료',
  '/home/statics/daily': '통계 (일간)',
  '/home/statics/weekly': '통계 (주간)',
  '/home/statics/monthly': '통계 (월간)',
  '/home/mealDetail/daily': '식단 상세 (일간)',
  '/home/mealDetail/weekly': '식단 상세 (주간)',
  '/home/mealDetail/monthly': '식단 상세 (월간)',
  '/search/main/0': '음식 검색 메인',
  '/search/main/1': '음식 검색 메인',
  '/search/main/2': '음식 검색 메인',
  '/search/main/3': '음식 검색 메인',
  '/search/result/0': '음식 검색 결과',
  '/search/result/1': '음식 검색 결과',
  '/search/result/2': '음식 검색 결과',
  '/search/result/3': '음식 검색 결과',
  '/recipe/0/1': '레시피 등록 스텝1',
  '/recipe/1/1': '레시피 등록 스텝1',
  '/recipe/2/1': '레시피 등록 스텝1',
  '/recipe/3/1': '레시피 등록 스텝1',
  '/recipe/0/2': '레시피 등록 스텝2',
  '/recipe/1/2': '레시피 등록 스텝2',
  '/recipe/2/2': '레시피 등록 스텝2',
  '/recipe/3/2': '레시피 등록 스텝2',
  '/recipe/0/3': '레시피 등록 스텝3',
  '/recipe/1/3': '레시피 등록 스텝3',
  '/recipe/2/3': '레시피 등록 스텝3',
  '/recipe/3/3': '레시피 등록 스텝3',
  '/recipe/edit/1': '레시피 수정 스텝1',
  '/recipe/edit/2': '레시피 수정 스텝2',
  '/recipe/edit/3': '레시피 수정 스텝3',
  '/search/main/recipe': '레시피 재료 검색 페이지',
  '/search/result/recipe': '레시피 재료 검색 결과',
  '/tags/water': '물 기록',
  '/tags/supplements': '영양제 기록',
  '/tags/bodyCondition': '컨디션 기록',
  '/SummaryView': '모아보기',
  '/userProfile/1': '프로필 입력 스텝1',
  '/userProfile/2': '프로필 입력 스텝2',
  '/userProfile/3': '프로필 입력 스텝3',
  '/userProfile/4': '프로필 입력 스텝4',
  '/exercise/search': '운동 검색 메인',
  '/exercise/category/1': '운동 카테고리 (어깨, 팔)',
  '/exercise/category/2': '운동 카테고리 (가슴)',
  '/exercise/category/3': '운동 카테고리 (등)',
  '/exercise/category/4': '운동 카테고리 (코어)',
  '/exercise/category/5': '운동 카테고리 (하체)',
  '/exercise/category/6': '운동 카테고리 (유산소)',
  '/exercise/category/7': '운동 카테고리 (스포츠)',
  '/exercise/category/99': '운동 카테고리 (기타)',
  '/exercise/walks': '걸음 수',
  '/calenderDiary': '캘린더',
  '/calenderDiary/form/sticker': '일기 스티커 작성 (스텝 1)',
  '/calenderDiary/form/memo': '일기 메모 작성 (스텝 2)',
  '/fasting/timer': '단식 타이머',
  '/fasting/record': '단식 기록',
  '/fasting/static': '단식 통계',
  '/communication/popular': '인기 홈',
  '/communication/anonymity/99': '대나무숲 카테고리 (전체)',
  '/communication/anonymity/98': '대나무숲 카테고리 (베스트)',
  '/communication/anonymity/17': '대나무숲 카테고리 (파릇10대)',
  '/communication/anonymity/18': '대나무숲 카테고리 (당당20대)',
  '/communication/anonymity/19': '대나무숲 카테고리 (멋진30대)',
  '/communication/anonymity/11': '대나무숲 카테고리 (다이어트)',
  '/communication/anonymity/12': '대나무숲 카테고리 (패션뷰티)',
  '/communication/anonymity/13': '대나무숲 카테고리 (직장생활)',
  '/communication/anonymity/14': '대나무숲 카테고리 (학교생활)',
  '/communication/anonymity/15': '대나무숲 카테고리 (연애고민)',
  '/communication/anonymity/16': '대나무숲 카테고리 (자유수다)',
  '/communication/anonymity/search': '대나무숲 검색',
  '/communication/anonymity/posting': '대나무숲 게시글 작성',
  '/communication/ketogram/99': '피드 카테고리 (전체)',
  '/communication/ketogram/98': '피드 카테고리 (베스트)',
  '/communication/ketogram/0': '피드 카테고리 (식단)',
  '/communication/ketogram/1': '피드 카테고리 (레시피)',
  '/communication/ketogram/2': '피드 카테고리 (일상)',
  '/communication/ketogram/3': '피드 카테고리 (팔로잉)',
  '/communication/ketogram/4': '피드 카테고리 (운동)',
  '/communication/ketogram/5': '피드 카테고리 (눈바디)',
  '/communication/ketogram/6': '피드 카테고리 (자극짤)',
  '/communication/ketogram/posting': '피드 작성',
  '/communication/ketogram/postingImmediately': '피드 작성 (레시피 바로 공유)',
  '/communication/board/99': '의견 카테고리 (전체)',
  '/communication/board/1': '의견 카테고리 (기능제안)',
  '/communication/board/2': '의견 카테고리 (오류신고)',
  '/communication/board/3': '의견 카테고리 (불편해요)',
  '/communication/board/posting': '의견 게시글 작성',
  '/battle': '배틀 메인',
  '/myPage': '마이 홈',
  '/myPage/news/3': '내 소식',
  '/myPage/userSearch': '유저 검색',
  '/myPage/myProfile': '프로필 수정',
  '/communication/ketogram/follow/0/0': '내 팔로워 리스트',
  '/communication/ketogram/follow/0/1': '내 팔로잉 리스트',
  '/myPage/setting': '설정',
  '/myPage/setting/alarm': '기능별 알림',
  '/myPage/setting/customAlarm': '습관 알림',
  '/myPage/setting/customAlarm/posting': '습관 알림 등록',
  '/myPage/setting/CustomAlarm/posting/edit': '습관 알림 등록',
  '/myPage/setting/updateNews': '업데이트 소식',
  '/myPage/setting/faq': '자주 묻는 질문',
  '/myPage/setting/termsPrivacy': '이용약관',
  '/termsNotion': '정보',
  '/privacyNotion': '개인정보 보호방침',
};

export const handleGA4URL = (url: string) => {
  return CONVERT_URL[url] || url;
};

const handleBattleTitle = (url: string) => {
  const split = url.split('/');
  const lastIndex = split[split.length - 1];
  const secondIndex = split[2];
  const battleHandler: Handler = {
    kcal: () => {
      return '1:1 배틀 상세 (칼로리)';
    },
    weight: () => {
      return '1:1 배틀 상세 (몸무게)';
    },
    favorite: () => {
      return '1:1 배틀 상세 (자주 먹는 음식)';
    },
    community: () => {
      return '1:1 배틀 상세 (먹스타그램)';
    },
    fasting: () => {
      return '1:1 배틀 상세 (단식)';
    },
    submit: () => {
      return '1:1 배틀 최종 몸무게 제출';
    },
    default: () => {
      const title: Convert = {
        chat: '1:1 배틀 채팅',
        detail: '1:1 배틀 상세 (대시보드)',
        contract: '1:1 배틀 계약서 상세',
        meal: '1:1 배틀 상세 (먹은 음식)',
      };

      return title[secondIndex] ?? url;
    },
  };
  return (battleHandler[lastIndex] || battleHandler.default)();
};

const handleSearchTitle = (url: string) => {
  const split = url.split('/');
  const secondIndex = split[2];

  const handle: Handler = {
    detail: () => {
      const FOOD_DETAIL_PAGE = /\/search\/detail\/[0-3]\/\d+/.test(url);
      if (FOOD_DETAIL_PAGE) {
        return '음식 상세 페이지';
      }
      if (url.includes('/search/detail/recipe')) {
        return '레시피 재료 상세 페이지';
      }
      if (url.includes('/search/detail/inout')) {
        return '내가 평가한 음식 상세 페이지';
      }
      return url;
    },
    evaluation: () => {
      return '내가 평가한 음식 다시 평가 (퀵 버튼)';
    },
    exercise: () => {
      return '운동 상세 페이지';
    },
    recipe: () => {
      return '레시피 상세';
    },
    default: () => {
      return url;
    },
  };

  return (handle[secondIndex] || handle.default)();
};

const handleCommunityTitle = (url: string) => {
  const split = url.split('/');
  const thirdIndex = split[3];

  const handle: Handler = {
    user: () => {
      return '다른 유저 채널';
    },
    follow: () => {
      const lastIndex = split[split.length - 1];
      const follower = lastIndex === '0';
      const following = lastIndex === '1';

      if (follower) {
        return '다른 유저 팔로워 리스트';
      }
      if (following) {
        return '다른 유저 팔로잉 리스트';
      }
      return url;
    },
    feed: () => {
      return '피드 상세 페이지';
    },
    comment: () => {
      return '피드 댓글';
    },
    postingDetail: () => {
      const isFeedDietShareDataDetail = split.length === 6;
      const isFeedRecipeShareDataDetail = split.length === 5;
      if (isFeedDietShareDataDetail) {
        return '피드 식단 공유 데이터 상세';
      }
      if (isFeedRecipeShareDataDetail) {
        return '피드 레시피 공유 데이터 상세';
      }
      return url;
    },
    default: () => {
      const isOtherUserDietFeedDetail = split.length === 6;
      const isFeedRecipeShareDataDetail = split.length === 5;

      if (isOtherUserDietFeedDetail) {
        return '다른 유저 식단 피드 자세히';
      }

      if (isFeedRecipeShareDataDetail) {
        return '다른 유저 레시피 피드 자세히';
      }

      return url;
    },
  };

  return (handle[thirdIndex] || handle.default)();
};

export const handleGA4Title = (url: string) => {
  if (Object.prototype.hasOwnProperty.call(CONVERT_TITLE, url)) {
    return CONVERT_TITLE[url];
  }

  const split = url.split('/');
  const firstIndex = split[1];
  const handle: Handler = {
    search: () => {
      return handleSearchTitle(url);
    },
    exercise: () => {
      if (url.includes('/exercise/detail/list')) {
        return '운동 장바구니';
      }
      if (url.includes('/exercise/detail')) {
        return '운동 추가 기록';
      }
      return url;
    },
    battle: () => {
      return handleBattleTitle(url);
    },
    myPage: () => {
      if (url.includes('/myPage/ketogram/feed')) {
        return '내 피드/스크랩 상세';
      }
      return url;
    },
    communication: () => {
      return handleCommunityTitle(url);
    },
    default: () => {
      if (url.includes('/mealList/reg')) {
        return '식단 장바구니';
      }
      return url;
    },
  };

  return (handle[firstIndex] || handle.default)();
};

export const onMakeGA4Event = ({ page, title }: Setting) => {
  ReactGA.send({ hitType: 'pageview', path: page, location: page, title: title });
};
