import TabNavigator from '@components/TabNavigator';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import usePostScroll from '@hooks/usePostScroll';
import { getToday } from '@utils/fotmatDate';
import { useLocation } from 'react-router-dom';

const CommunicationNavigator = () => {
  const location = useLocation();
  const { sendAdBrixData } = useAdBrix();
  const scrollState = usePostScroll();
  const path = location.pathname;

  const communicationPaths = [
    '/anonymity/recommend',
    '/anonymity/all',
    '/anonymity/popular',
    '/ketogram/recommend',
    '/ketogram/all',
    '/ketogram/following',
    '/ketogram/popular',
  ];

  const ketogramPaths = ['/communication/ketogram/user', '/communication/ketogram/feeds'];

  const communityNavigatorTabs = [
    {
      origin: '/communication/anonymity',
      path: '/communication/anonymity/recommend',
      name: '대나무숲',
      width: 80,
      onClick: () => {
        sendAdBrixData('customEvent', {
          eventKey: 'click_toggle_forest',
        });
      },
    },
    {
      origin: '/communication/ketogram',
      path: `/communication/ketogram/recommend?tab=실시간&startDate=${getToday()}&endDate=${getToday()}`,
      name: '피드',
      width: 54,
      onClick: () => {
        sendAdBrixData('customEvent', {
          eventKey: 'click_toggle_feed',
        });
      },
    },
  ];

  const getKetogramUserNavigationPaths = (memberId: string | null) => [
    {
      origin: '/communication/ketogram/feeds',
      path: `/communication/ketogram/feeds/${memberId}`,
      name: '피드',
      width: 54,
    },
    {
      origin: '/communication/ketogram/user',
      path: `/communication/ketogram/user/${memberId}`,
      name: '메인룸',
      width: 67,
    },
  ];

  let tabs;
  let bottoms = 84;
  let replace = false;

  if (communicationPaths.some((p) => path.includes(p))) {
    tabs = communityNavigatorTabs;
  } else if (ketogramPaths.some((p) => path.includes(p))) {
    const match = path.match(/\/(\d+)$/);
    const id = match ? match[1] : null;
    bottoms = 20;
    tabs = getKetogramUserNavigationPaths(id);
    replace = true;
  }

  if (!tabs) return <></>;

  return (
    <>{scrollState !== 'down' && <TabNavigator tabs={tabs} bottom={bottoms} replace={replace} />}</>
  );
};

export default CommunicationNavigator;
