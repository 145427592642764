import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface Tab {
  origin: string;
  path: string;
  name: string;
  width: number;
  onClick?: () => void;
}

interface Props {
  tabs: Tab[];
  bottom?: number;
  replace?: boolean;
}

const TabNavigator = ({ tabs, bottom = 84, replace = false }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeIndex = tabs.findIndex((tab) => location.pathname.includes(tab.origin));

  const leftPosition = tabs.slice(0, activeIndex).reduce((acc, tab) => acc + tab.width, 4);
  const totalWidth = tabs.reduce((acc, tab) => acc + tab.width, 0) + 8;

  return (
    <Portal>
      <NavigationContainer totalWidth={totalWidth} bottom={bottom}>
        <TabContainer>
          <ActiveTabBackground width={tabs[activeIndex]?.width} left={leftPosition} />
          {tabs.map((tab) => (
            <Tab
              key={tab.path}
              width={tab.width}
              onClick={() => {
                if (activeIndex === tabs.findIndex((t) => t.path === tab.path)) return;
                if (tab.onClick) {
                  tab.onClick();
                }
                navigate(tab.path, { replace });
                window.scrollTo(0, 0);
              }}
            >
              {tab.name}
            </Tab>
          ))}
        </TabContainer>
      </NavigationContainer>
    </Portal>
  );
};

export default TabNavigator;

const NavigationContainer = styled.div<{ totalWidth: number; bottom: number }>`
  position: fixed;
  left: 50%;
  bottom: ${({ bottom }) => `${bottom}px`};
  width: ${({ totalWidth }) => `${totalWidth}px`};
  height: 44px;
  z-index: 10;
  transform: translateX(-50%);
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 25px;
  padding: 4px;
  position: relative;
`;

const Tab = styled.button<{ width: number }>`
  height: 36px;
  text-align: center;
  border: none;
  width: ${({ width }) => `${width}px`};
  color: ${COLORS.WHITE};
  position: relative;
  z-index: 2;
  font-family: 'Noto Sans KR';
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1px;
  background: none;
`;

const ActiveTabBackground = styled.div<{ width: number; left: number }>`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 120px;
  transition: all 0.35s ease-in-out;
  will-change: left, width;
  top: 4px;
  bottom: 4px;
  left: ${({ left }) => `${left}px`};
`;
