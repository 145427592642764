import { MyRoomDetailType, MyRoomThumbnail, RoomLikes } from '@models/myRoom';
import { StoreMyRoom } from '@models/store';
import { get, post } from '@modules/HttpWebClient/default';

export const getMyRoomPurchasedItemList = async (memberIdx: number, type?: string) => {
  const url = `/v2/members/${memberIdx}/room/items/purchased`;
  const { data } = await get<StoreMyRoom[]>(url, {
    params: {
      type,
    },
  });

  return data;
};

export const getMyRoomLikeItemList = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/room/items/like`;
  const { data } = await get<StoreMyRoom[]>(url);

  return data;
};

export const getMyRoomDetail = async (memberIdx: number, roomId: string) => {
  const url = `/v2/members/${memberIdx}/room/${roomId}`;
  const { data } = await get<MyRoomDetailType>(url);

  return data;
};

export const getMyRoomList = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/room`;
  const { data } = await get<MyRoomThumbnail[]>(url);

  return data;
};

export const postMyRoomSelect = async (memberIdx: number, roomId: string) => {
  const url = `/v2/members/${memberIdx}/room/main`;

  const { status } = await post(url, {
    room_id: roomId,
  });

  return status === 200;
};

export const postMyRoomItemListPurchase = async ({
  memberIdx,
  room_item_idxes,
  timezone,
}: {
  memberIdx: number;
  room_item_idxes: number[];
  timezone: string;
}) => {
  const url = `/v2/members/${memberIdx}/room/items?timezone=${timezone}`;

  const { status } = await post(url, {
    room_item_idxes,
  });

  return status === 200;
};

export const getRoomLikes = async (memberIdx: number, roomId: string) => {
  const url = `/v2/members/${memberIdx}/room/${roomId}/likes`;
  const { data } = await get<RoomLikes>(url);

  return data;
};
