import { ResponseMsg } from '../types/app';
import { post } from '../modules/HttpWebClient/default';

export async function setFcmToken(
  token: any,
  os: 'ios' | 'android',
  permission: 'Y' | 'N' | 'null',
): Promise<ResponseMsg> {
  const url = '/auth/device/fcm/token';
  const { data } = await post<ResponseMsg>(url, {
    fcmPushToken: token,
    deviceOs: os,
    permission: permission,
  });
  return data;
}
