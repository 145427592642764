import {
  CommunityActiveIcon,
  CommunityIcon,
  MyRoomActiveIcon,
  MyRoomIcon,
  RecordActiveIcon,
  RecordIcon,
  BattleIcon,
  BattleActiveIcon,
} from 'assets/svgs/tabBar';
import { exerciseThemeIndex } from 'pages/exercise/states';
import { myChangeTabIndexState, myEatTabIndexState } from 'pages/main/states';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import {
  ApplePointCenterIcon,
  ApplePointCenterOffIcon,
  ApplePointCenterGoldIcon,
} from '@assets/svgs/illust';
import { COLORS } from '@styles/constants/_colors';
import useNavigationNewsQuery from '@hooks/queries/useNavigationNewsQuery';
import { anonymityKeys } from '@constants/query';

import AppleStarGold from '@assets/images/png/appleStarGold.png';
import AppleStarOff from '@assets/images/png/appleStarOff.png';
import { findWeekend } from '@utils/timeUtil';

interface Props {
  index: number;
  isSelected: boolean;
}

export default function Menu({ index, isSelected }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: newsState } = useNavigationNewsQuery();

  const [, setMyChangeTabIndex] = useRecoilState(myChangeTabIndexState);
  const [, setMyEatTabIndex] = useRecoilState(myEatTabIndexState);
  const [, SetExerciseThemeIndex] = useRecoilState(exerciseThemeIndex);

  const isWeekend = findWeekend();

  const menuList = [
    { title: '기록', icon: <RecordIcon />, selectedIcon: <RecordActiveIcon /> },
    { title: '배틀', icon: <BattleIcon />, selectedIcon: <BattleActiveIcon /> },
    { title: '커뮤니티', icon: <CommunityIcon />, selectedIcon: <CommunityActiveIcon /> },
    {
      title: '사과농장',
      icon: (
        <div style={{ position: 'relative' }}>
          <img
            src={AppleStarOff}
            style={{ width: '5px', height: '5px', position: 'absolute', top: 0, right: 2 }}
            alt=""
          />
          <ApplePointCenterOffIcon style={{ marginLeft: '1px' }} />
        </div>
      ),
      selectedIcon: isWeekend ? (
        <div style={{ position: 'relative' }}>
          <img
            src={AppleStarGold}
            style={{ width: '5px', height: '5px', position: 'absolute', top: 0, right: 0 }}
            alt=""
          />
          <ApplePointCenterGoldIcon style={{ marginLeft: '1px' }} />
        </div>
      ) : (
        <ApplePointCenterIcon style={{ marginLeft: '1px' }} />
      ),
      accent: true,
    },
    {
      title: '마이룸',
      icon: <MyRoomIcon />,
      selectedIcon: <MyRoomActiveIcon />,
    },
  ];
  const currentMenu = menuList[index];

  const hasNews = (index === 4 && newsState?.myProfile) || (index === 1 && newsState?.battle);

  const resetMainIndex = () => {
    setMyChangeTabIndex(0);
    setMyEatTabIndex(0);
    SetExerciseThemeIndex(0);
  };

  const removeScrollRestorationItems = () => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith('useScrollRestoration')) {
        sessionStorage.removeItem(key);
      }
    });
  };

  const moveToPage = (index: number) => {
    removeScrollRestorationItems();
    if (isSelected) {
      window.scrollTo(0, 0);
      return;
    }

    switch (index) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/battle/group');
        break;
      case 2:
        queryClient.resetQueries([anonymityKeys.RECOMMEND]);
        queryClient.resetQueries(['feed', 'V2']);
        queryClient.resetQueries(['feedRecommend']);
        navigate('/communication/anonymity/recommend');
        break;
      case 3:
        navigate('/apple/main');
        break;
      case 4:
        navigate('/myPage/mainRoom');
        break;
      default:
        navigate('/');
    }

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isSelected && index !== 0) {
      resetMainIndex();
    }
  }, [isSelected, index]);

  return (
    <>
      <MenuItemBox
        $accent={!!currentMenu.accent}
        $selected={isSelected}
        onClick={() => moveToPage(index)}
      >
        <IconBox>
          {isSelected ? currentMenu.selectedIcon : currentMenu.icon}
          {hasNews && <NewsDot />}
        </IconBox>
        <span
          style={{
            color:
              isSelected && isWeekend && currentMenu.title === '사과농장' ? '#E9A300' : undefined,
          }}
        >
          {currentMenu.title}
        </span>
      </MenuItemBox>
    </>
  );
}

const MenuItemBox = styled.div<{ $selected: boolean; $accent: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 64px;
  margin-bottom: 2px;

  > span {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: ${COLORS.PRIMITIVES_GRAY_500};

    ${({ $selected, $accent }) =>
      $selected &&
      css`
        color: ${$accent ? '#FF4800' : COLORS.BLACK};
        font-weight: 700;
      `}
  }
`;

const IconBox = styled.div`
  position: relative;
  height: 30px;
  margin-top: 2px;
  margin-bottom: 1px;

  > svg {
    height: 32px;
  }
`;

const NewsDot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  right: 0;
  top: 0;
  border-radius: 50%;
  background-color: ${COLORS.RED};
`;
